
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;

$alert_text_red: #f00;
$alert_red: #f00;
$success: #00a740;

.container {
  position: relative;
  padding: 6px 30px 6px 16px !important;
  margin: auto;

  @include media_min(sm) {
    min-width: 500px !important;
    font-size: 1.6rem !important;
  }

  @include media(sm) {
    font-size: 1.4rem !important;
  }

  :global(.MuiSnackbarContent-message) {
    padding-right: 8px;
  }

  :global(.MuiSnackbarContent-action) {
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 0 !important;
    margin: 0 -12px 0 0 !important;
    transform: translateY(-50%);
  }

  .closeIcon {
    width: 28px;
    height: 28px;
  }

  &.success {
    background-color: $success !important;
  }

  &.alert {
    background-color: $alert_red !important;
  }
}
