
    @use '@variables_common' as *;
    
@use '@variables_iqfu' as *;

.loading_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ball_clip_rotate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: transparent !important;
    border: 2px solid #fe9210;
    border-bottom-color: transparent;
    border-radius: 100%;
    animation: rotate 0.75s 0s linear infinite;
    animation-fill-mode: both;
  }
}
