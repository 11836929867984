
    @use '@variables_common' as *;
    
/* stylelint-disable-next-line no-invalid-position-at-import-rule */

.MuiCalendarPicker-root {
  .MuiButton-textSizeMedium,
  .PrivatePickersMonth-root {
    font-size: 1.5rem;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    margin-top: 16px;
  }

  .MuiPickersDay-root,
  .MuiTypography-caption {
    width: 36px;
    height: 36px;
    font-size: 1.5rem;
  }

  .PrivatePickersYear-yearButton,
  [role='presentation'] {
    font-size: 1.6rem;
    text-align: center;
  }
}

html {
  font-family: Hiragino Kaku Gothic ProN, Yu Gothic, YuGothic, Meiryo, sans-serif;
  font-size: 62.5%;
  font-feature-settings: 'palt';
}

body {
  font-size: min(1.6rem, 16px);
  line-height: 1.5;
  word-break: break-all;
  overflow-wrap: anywhere;
}

select {
  min-width: 100px;

  @include form_reset_common;
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='tel'],
textarea {
  @include form_reset_common;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  display: block;
  font-size: min(1.6rem, 16px);
  border-radius: 4px;
}

.pc_only {
  @include media(lg) {
    display: none !important;
  }
}

.sp_only {
  @include media_min(lg) {
    display: none !important;
  }
}

.MuiSnackbarContent-message {
  margin: 0 auto !important;
}

.MuiAutocomplete-root {
  .MuiAutocomplete-endAdornment {
    .MuiButtonBase-root {
      height: 28px;
    }
  }

  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiChip-label {
    font-size: 13px;
  }

  .MuiOutlinedInput-input.MuiInputBase-input.MuiAutocomplete-input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    @include form_reset_common;

    padding: 0.456em 1em;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-fullWidth,
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
    padding: 0;
  }

  .MuiOutlinedInput-input.MuiInputBase-input.MuiAutocomplete-input::placeholder,
  .MuiOutlinedInput-input.MuiInputBase-input.MuiAutocomplete-input:placeholder {
    opacity: 1;
  }
}

.MuiAutocomplete-paper {
  max-width: 400px !important; // プルダウン
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-listbox,
  .MuiAutocomplete-noOptions {
    font-size: min(1.6rem, 20px) !important;
    color: #444;
  }
}

//都道府県のインプットのplaceholder
.MuiInputBase-input.MuiInputBase-input::placeholder {
  color: $placeholder_text_color;
  opacity: 1;
}

.util-formWidth1 {
  width: 400px !important;

  @include media(lg) {
    width: 100% !important;
  }
}

.util-formWidth2 {
  width: 200px !important;
}

.util-formWidth3 {
  width: 550px !important;

  @include media(lg) {
    width: 100% !important;
  }
}

.no_icon .MuiSvgIcon-root {
  display: none !important;
}

img {
  height: auto;
}

.yellow_label {
  background: linear-gradient(transparent 65%, #fcff75 65%);
}

.orange_underline {
  border-bottom: 4px solid #fe912099;

  @include media(lg) {
    border-bottom: 3px solid #fe912099;
  }
}

.bold {
  font-weight: bold;
}
